exports.components = {
  "component---gatsby-theme-src-pages-404-js": () => import("./../../../../gatsby-theme/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-src-pages-404-js" */),
  "component---gatsby-theme-src-pages-using-typescript-tsx": () => import("./../../../../gatsby-theme/src/pages/using-typescript.tsx" /* webpackChunkName: "component---gatsby-theme-src-pages-using-typescript-tsx" */),
  "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2023-09-09-markdown-mdx": () => import("./../../../../gatsby-theme/src/templates/blog-post.js?__contentFilePath=/opt/build/repo/site/content/blogs/2023--09--09--markdown.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2023-09-09-markdown-mdx" */),
  "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2023-10-14-twitter-ui-mdx": () => import("./../../../../gatsby-theme/src/templates/blog-post.js?__contentFilePath=/opt/build/repo/site/content/blogs/2023--10--14--twitter-ui.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2023-10-14-twitter-ui-mdx" */),
  "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2023-10-22-giscus-mdx": () => import("./../../../../gatsby-theme/src/templates/blog-post.js?__contentFilePath=/opt/build/repo/site/content/blogs/2023--10--22--giscus.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2023-10-22-giscus-mdx" */),
  "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2024-05-22-algolia-mdx": () => import("./../../../../gatsby-theme/src/templates/blog-post.js?__contentFilePath=/opt/build/repo/site/content/blogs/2024--05--22--algolia.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2024-05-22-algolia-mdx" */),
  "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2024-08-30-garmin-index-mdx": () => import("./../../../../gatsby-theme/src/templates/blog-post.js?__contentFilePath=/opt/build/repo/site/content/blogs/2024--08--30--garmin/index.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-blogs-2024-08-30-garmin-index-mdx" */),
  "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-soft-dev-2023-10-09-mermaidjs-mdx": () => import("./../../../../gatsby-theme/src/templates/blog-post.js?__contentFilePath=/opt/build/repo/site/content/soft-dev/2023--10--09--mermaidjs.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-blog-post-js-content-file-path-content-soft-dev-2023-10-09-mermaidjs-mdx" */),
  "component---gatsby-theme-src-templates-index-post-js-content-file-path-content-blogs-index-mdx": () => import("./../../../../gatsby-theme/src/templates/index-post.js?__contentFilePath=/opt/build/repo/site/content/blogs/index.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-index-post-js-content-file-path-content-blogs-index-mdx" */),
  "component---gatsby-theme-src-templates-index-post-js-content-file-path-content-index-mdx": () => import("./../../../../gatsby-theme/src/templates/index-post.js?__contentFilePath=/opt/build/repo/site/content/index.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-index-post-js-content-file-path-content-index-mdx" */),
  "component---gatsby-theme-src-templates-index-post-js-content-file-path-content-physics-index-mdx": () => import("./../../../../gatsby-theme/src/templates/index-post.js?__contentFilePath=/opt/build/repo/site/content/physics/index.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-index-post-js-content-file-path-content-physics-index-mdx" */),
  "component---gatsby-theme-src-templates-index-post-js-content-file-path-content-soft-dev-index-mdx": () => import("./../../../../gatsby-theme/src/templates/index-post.js?__contentFilePath=/opt/build/repo/site/content/soft-dev/index.mdx" /* webpackChunkName: "component---gatsby-theme-src-templates-index-post-js-content-file-path-content-soft-dev-index-mdx" */)
}

